<template>
  <div class="container error__page">
    <div class="columns is-multilined">
      <div class="column is-6">
        <img
          src="../../assets/images/ErrorPage/403.svg"
          class="error__img-container"
        />
      </div>
      <div class="column is-6">
        <div class="error__text-content">
          <h1 class="error__header">403 Access Forbidden</h1>
          <p class="error__content">
            The page you're trying to go has restricted access. Please refer to
            your system administrator.
          </p>
          <b-button
            type="is-primary"
            @click.native="goToDashboard()"
            label="Back to Dashboard"
          ></b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goToDashboard() {
      this.$router.push('/')
    },
  },
}
</script>
